/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslate } from 'react-admin';
import commonProptypes from '../../utils/commonProptypes';

function CondoAndGroupFormLine({
  handleSubmit,
  index,
  condominiums,
  groups,
  defaultValue,
  showMainFacilitatorButton,
  handleMainFacilitatorClick,
  mainFacilitatorLoading,
}) {
  // local state
  const [condo, setCondo] = useState(null);
  const [group, setGroup] = useState(null);
  const [isMainFacilitator, setIsMainFacilitator] = useState(false);

  // hooks
  const t = useTranslate();

  // lifecycle
  useEffect(() => {
    if (group && condo) {
      handleSubmit({
        condominium: condo, group, index,
      });
    }
  }, [condo, group]);

  useEffect(() => {
    setCondo(defaultValue?.condominium || null);
    setGroup(defaultValue?.group || null);
    setIsMainFacilitator(defaultValue?.isMainFacilitator);
  }, [defaultValue]);

  return (
    <div className="flex row wrap mr-l mb-m">
      <div className="mr-m">
        <Autocomplete
          options={condominiums}
          sx={{ width: 300 }}
          onChange={(event, value) => {
            setCondo(value);
          }}
          noOptionsText={t('labels.noResults')}
          disabled={!!condo}
          value={condo}
          getOptionLabel={(item) => item?.entitled}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('labels.condominium')}
            />
          )}
        />
      </div>
      <div className="mr-m">
        <Autocomplete
          options={groups}
          sx={{ width: 300 }}
          onChange={(event, value) => {
            setGroup(value);
          }}
          noOptionsText={t('labels.noResults')}
          value={group}
          getOptionLabel={(item) => t(`labels.groups.${item?.code}`)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('labels.role')}
            />
          )}
        />
      </div>
      {
        showMainFacilitatorButton && group?.code === 'facilitator'
          ? (
            <LoadingButton
              size="small"
              color="secondary"
              variant="contained"
              disabled={isMainFacilitator}
              loading={mainFacilitatorLoading}
              onClick={() => handleMainFacilitatorClick(condo?.id)}
            >
              {isMainFacilitator ? t('labels.mainFacilitator') : t('labels.becomeMainFacilitator')}
            </LoadingButton>
          ) : null
      }
    </div>
  );
}

CondoAndGroupFormLine.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  condominiums: PropTypes.arrayOf(commonProptypes.condominiumType).isRequired,
  groups: PropTypes.arrayOf(commonProptypes.groupType).isRequired,
  defaultValue: PropTypes.shape({
    condominium: commonProptypes.condominiumType,
    group: commonProptypes.groupType,
    isMainFacilitator: PropTypes.bool,
  }),
  showMainFacilitatorButton: PropTypes.bool,
  mainFacilitatorLoading: PropTypes.bool,
  handleMainFacilitatorClick: PropTypes.func,
};

CondoAndGroupFormLine.defaultProps = {
  defaultValue: null,
  showMainFacilitatorButton: false,
  mainFacilitatorLoading: false,
  handleMainFacilitatorClick: () => {},
};

export default CondoAndGroupFormLine;
