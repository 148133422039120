import React, { useMemo } from 'react';
import {
  AutocompleteArrayInput,
  BooleanInput,
  DateTimeInput,
  FileInput,
  required, SimpleForm, TextInput, useNotify, useTranslate,
} from 'react-admin';
import { useQuery } from 'react-query';

// Service
import CondominiumService from '../../../services/CondominiumService';
// Utils
import formatErrorMessage from '../../../utils/formatErrorMessage';
import FileInputPreview from '../../atoms/FileInputPreview';

function PublicationForm() {
  const notify = useNotify();
  const getCondominiumsQuery = useQuery('getCondominiums', () => CondominiumService.getCondominium(), {
    onError: (err) => notify(formatErrorMessage(err), { type: 'error' }),
  });
  const t = useTranslate();

  const condominiumChoices = useMemo(() => {
    const allOption = [{
      entitled: t('labels.all'),
      id: getCondominiumsQuery?.data?.data?.condominiums?.map((item) => item?.id),
    }];
    return allOption.concat(getCondominiumsQuery?.data?.data?.condominiums || []);
  }, [getCondominiumsQuery?.data]);

  return (
    <SimpleForm>
      <div>
        <div className="flex row wrap">
          <div className="mr-m">
            <DateTimeInput
              source="publishDate"
              name="publishDate"
              defaultValue={new Date()}
              label={t('labels.publishDate')}
              validate={[required()]}
            />
          </div>
          <DateTimeInput
            source="unpublishDate"
            name="unpublishDate"
            label={t('labels.unPublishDate')}
            validate={[required()]}
          />
        </div>
        <div className="flex row wrap">
          <BooleanInput
            source="isActive"
            label={t('labels.active')}
          />
          <BooleanInput
            source="promote"
            label={t('labels.promote')}
          />
        </div>
        <AutocompleteArrayInput
          name="condominiums"
          source="condominiums"
          choices={condominiumChoices}
          label={t('labels.condominiums')}
          optionText="entitled"
          format={(value) => {
            if (value?.find((val) => Array.isArray(val))) {
              return value?.find((val) => Array.isArray(val));
            }
            return (value?.[0]?.id ? value?.map((v) => v.id) : value);
          }}
          validate={[required()]}
          loading={getCondominiumsQuery.isLoading}
          noOptionsText={t('labels.noResults')}
        />
        <TextInput
          source="description"
          multiline
          fullWidth
          validate={[required()]}
        />
        <FileInput
          source="picture"
          label={t('labels.picture')}
          accept="image/png, image/jpeg"
          validate={[required()]}
        >
          <FileInputPreview source="src" />
        </FileInput>
      </div>
    </SimpleForm>

  );
}

export default PublicationForm;
