import React from 'react';
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  TextField,
  useList,
  useRecordContext,
  ListContextProvider,
  NumberField,
  EmailField, SimpleList, useTranslate, TextInput, AutocompleteInput, useNotify, useLocaleState,
} from 'react-admin';
import { fr } from 'date-fns/locale';
import { useMediaQuery } from '@mui/material';
import { format } from 'date-fns';
import { useQuery } from 'react-query';
import CondominiumService from '../../../services/CondominiumService';
import formatErrorMessage from '../../../utils/formatErrorMessage';

const longDateOptions = {
  weekday: 'long',
  day: 'numeric',
  month: 'long',
  year: 'numeric',
};

const hourOptions = {
  hour: 'numeric',
  minute: 'numeric',
};

function ExpandInfo() {
  // hooks
  const record = useRecordContext();
  const listContext = useList({ data: Object.values(record)?.[0] });

  const renderFacilitatorField = (rec) => (
    <div>
      <p className="m-0">
        {`${rec?.facilitatorInfosDTO.firstName || '_'} ${
          rec?.facilitatorInfosDTO.lastName || '_'}`}
      </p>
      <EmailField source="facilitatorInfosDTO.email" record={rec} />
    </div>
  );

  const [locale] = useLocaleState();

  return (
    <ListContextProvider value={listContext}>
      <Datagrid bulkActionButtons={false}>
        <DateField
          source="startDate"
          label="labels.startDate"
          showdate="false"
          showTime
          locales={locale}
          options={hourOptions}
        />
        <DateField
          source="endDate"
          label="labels.endDate"
          showdate="false"
          showTime
          locales={locale}
          options={hourOptions}
        />
        <TextField label="labels.condominium" source="condominium.entitled" />
        <FunctionField label="labels.mainFacilitator" render={renderFacilitatorField} />
        <NumberField source="countAttendees" label="labels.countAttendees" />
      </Datagrid>
    </ListContextProvider>
  );
}

function AppointmentList() {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const t = useTranslate();
  const notify = useNotify();
  const getCondominiumsQuery = useQuery('getCondominiums', () => CondominiumService.getCondominium(), {
    onError: (err) => notify(formatErrorMessage(err), { type: 'error' }),
  });
  const [locale] = useLocaleState();

  const renderDateField = (record) => (
    <DateField
      record={Object.values(record)?.[0]?.[0]}
      source="startDate"
      options={longDateOptions}
      locales={locale}
    />
  );

  const getAppointmentsFilter = () => ([
    <TextInput
      name="facilitator"
      label="labels.groups.facilitator"
      source="facilitator"
      alwaysOn
      resettable
      sx={{ marginTop: '5px' }}
    />,
    <AutocompleteInput
      name="condominiumId"
      source="condominiumId"
      label="labels.condominiums"
      choices={getCondominiumsQuery?.data?.data?.condominiums || []}
      optionText="entitled"
      sx={{
        width: 220,
        marginTop: '15px',
      }}
      alwaysOn
    />,
  ].filter((filter) => filter !== null)
  );

  return (
    <List
      filters={getAppointmentsFilter()}
      debounce={500}
      exporter={false}
    >
      {isSmall
        ? (
          <SimpleList
            primaryText={(rec) => {
              const record = Object.values(rec)?.[0]?.[0];
              return (record?.endDate ? `${t('labels.the')} ${
                format(new Date(record.startDate), 'eeee dd MMMM yyyy', { locale: fr })} ${
                t('labels.fromSimple')} ${format(new Date(record.startDate), 'HH:mm', { locale: fr })} ${
                t('labels.toSimple')} ${format(new Date(record.endDate), 'HH:mm', { locale: fr })}` : '');
            }}
            tertiaryText={(rec) => `${Object.values(rec)?.[0]?.[0]?.countAttendees} ${
              t('labels.countAttendees')}`}
            secondaryText={
              (rec) => {
                const record = Object.values(rec)?.[0]?.[0];
                return `${
                  record?.facilitatorInfosDTO?.firstName || '_'} ${
                  record?.facilitatorInfosDTO?.lastName || '_'} ${
                  record?.facilitatorInfosDTO?.email || '_'}`;
              }
            }
          />
        )
        : (
          <Datagrid
            rowClick="expand"
            expand={<ExpandInfo />}
            bulkActionButtons={false}
          >

            <FunctionField label="labels.appointmentDate" render={renderDateField} />
          </Datagrid>
        )}
    </List>
  );
}

export default AppointmentList;
