const RaFileInput = {
  styleOverrides: {
    root: {
      '& .RaFileInput-removeButton': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
    },
  },
};

export default RaFileInput;
