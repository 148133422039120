import React from 'react';
import { useRecordContext } from 'react-admin';

function FileInputPreview(props) {
  const record = useRecordContext(props);
  return (
    <img
      alt="preview"
      src={record?.src || record}
      className="object-contain"
      style={{ width: 100 }}
    />
  );
}

export default FileInputPreview;
