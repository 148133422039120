import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import { TextField } from 'react-admin';

function StringListField({ listField }) {
  const condominiumString = `${listField?.[0]} ${listField?.length > 1 ? '...' : ''}`;
  const allCondominiumString = listField?.join('\n');
  return (
    <Tooltip
      title={listField?.length > 1
        ? <div style={{ whiteSpace: 'pre-line', maxHeight: 100, overflow: 'auto' }}>{allCondominiumString}</div> : ''}
      arrow
    >
      <div>
        <TextField
          source="value"
          record={{ value: condominiumString }}
        />
      </div>
    </Tooltip>
  );
}

StringListField.propTypes = {
  listField: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default StringListField;
